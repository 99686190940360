import React, { createContext, useState, useContext } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const login = async (username, password) => {
    try {
      const body = new URLSearchParams();
      body.append("username", username);
      body.append("password", password);

      const response = await fetch(
        "/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: body,
        }
      );

      if (response.ok) {
        const { access_token, token_type, username } = await response.json();
        setCookie("access_token", access_token, 1);
        setLoggedIn(true);
        return { ok: true };
      } else {
        console.error("Login failed");
        return { ok: false };
      }
    } catch (error) {
      console.error("Error during login:", error);
      return { ok: false };
    }
  };

  const logout = () => {
    setLoggedIn(false);
    // 로그아웃 로직 (예: 토큰 삭제)
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
